const Dashboard = () => {

  document.body.setAttribute('id', 'dashboard-page');
  return (
    <>
      Welcome
    </>
  )
}

export default Dashboard;